
.credit input {
  padding: 0.35em;
  margin: 0.5em 0;
  display: block;
}

.credit .input-group {
  display: inline-block;
  position: relative;
}


.credit small {
  color: red;
  margin-top: -5px;
  font-weight: bold;
  font-size: 10px;
  display: block;
}


.extlink:hover::after {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-left: 5px;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6.343 17.657L17.657 6.343m0 0v8.485m0-8.485H9.172'/%3E%3C/svg%3E");
  background-color: currentColor;
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  content: "";
}

.deploy-button:hover  span{
  transform: translateX(-20px);
  transition: transform 0.3s;
}

.deploy-button:hover .animateIcon{
  transition: opacity 0.3s;
  opacity: 1;
}
/* .deploy-button  span::after{
  visibility: hidden;
  position: absolute;
  left: 40px;
  top: 20px;
}

.deploy-button:hover  span::after{
  display: inline-block !important;
  visibility: visible !important;
  width: 0.63em;
  height: 1em;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23000' d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256L34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941'/%3E%3C/svg%3E");
  background-color: currentColor;
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  content: "";
} */
