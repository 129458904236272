.bgCustom{
    background-image: url(../../assets/img/keepsecbackgroud-Recovered.gif) !important;
    background-size: cover;
    background-repeat: no-repeat;
}


.myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

.content {
    /* background: rgba(0, 0, 0, 0.5); */
    
  }