@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  scrollbar-width: thin;
  scrollbar-color: #1d1d1d;
}


/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #1d1d1d;
}

*::-webkit-scrollbar-thumb {
  background-color: #bccbd3;
  border-radius: 20px;
  border: 3px solid #1d1d1d;
}


body{
    /* background-color: white; */
    /* overflow-x: hidden !important;
    overflow-y: scroll !important; */
    overflow: hidden !important;
    position: relative !important;
    min-height: 100% !important;
    top: 0px !important;
}

.hide-crisp .cc-1brb6{
  display: none !important;
}
.do-Icon--large {
    height: 1.5em;
    width: 1.5em;
}
.do-Icon {
    fill: currentColor;
    height: 1em;
    line-height: 1;
    max-width: 100%;
    width: 1em;
}

#components-popconfirm-demo-placement .ant-btn {
  margin-left: 0;
  margin-right: 8px;
  margin-bottom: 8px;
  width: 70px;
  text-align: center;
  padding: 0;
}
#components-popconfirm-demo-placement .ant-btn-rtl {
  margin-left: 8px;
  margin-right: 0;
}

.nc-footer{
  display: none !important;
}
[data-test-id="user-preference-cog"]{
  display: none !important;
}

.siderbar .ant-collapse-ghost >.ant-collapse-item >.ant-collapse-content >.ant-collapse-content-box{
  padding-top: 0px !important;
}
.siderbar .ant-collapse-borderless >.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box{
  padding-top: 0px !important;
}
.siderbar .ant-collapse .ant-collapse-content>.ant-collapse-content-box{
  padding: 0px 0px !important;
}

.siderbar .ant-collapse>.ant-collapse-item >.ant-collapse-header{
  padding: 10px 5px !important;
}

.menuItem{
  font-weight: bold !important;
}

.ant-modal-content{
  padding: 0px !important;
}

.css-kvtuwg{
  display: none !important;
}
.ant-table-wrapper tfoot>tr>td{
  padding: 10px !important;
}
.ant-drawer-header{
  background-color: #011a43 !important;
}
.ant-drawer-title, .ant-drawer-close .anticon svg{
  color: white !important;
  fill: white !important;
}

button.ant-switch{
  background-color: rgba(0, 0, 0, 0.25);
}

.Agdasima{
  font-family: 'Agdasima', sans-serif !important;
}
.dash-back{
  background-image: url(./assets/img/Background_2_support.svg) !important;
  background-size: contain;
  z-index: -1;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;

  /*background-color: red !important;*/
}
.preview-back{
  background-image: url(./assets/img/login_background.png) !important;
  background-size: cover;
  z-index: -1;
  background-position: bottom;
  background-repeat: no-repeat;
/*  background-attachment: fixed;*/

  /*background-color: red !important;*/
}

.expandable {
  padding-top: 10px;
  overflow: hidden;
  height: 0px;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s;
}

.darkBg{
  background-image: url(./assets/img/dark.png) !important;
  background-size: contain;
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
} 
.lighBg{
  background-image: url(./assets/img/light.png) !important;
  background-size: contain;
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
} 
.support-back{
  background-image: url(./assets/img/Background_2_support.svg) !important;
  background-size: contain;
  z-index: -1;
  background-position: bottom;
  background-repeat: no-repeat; 
}

.f-show{
  visibility: visible !important;
}
a{
    font-weight: 600 !important;
    /* color: #011a43 !important; */
}

.volume .ant-select-selector{
  border-radius: 0px !important;
}

.webapp a {
    color: #7dd3fc !important;
}

.anticon svg{
  display: block !important;
}
.ant-btn-primary {
  background-color: #011a43 !important;
}
#intro.intro-cloudalt {
    text-align: center;
  }
  .scroll:hover {
    font-weight: bold;
  }
  .bgt {
    background-color: #1b142e;
  }
  .bgt:hover {
    background-color: #402184;
  }
  

  
  .tp-pitch-small.tp-pitch-punchy {
    color: hsla(0, 0%, 100%, 0.5);
    padding: 15px 35px;
  }
  .tp-pitch-small {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50px;
    display: table;
    /* font-size: 1.35em; */
    margin: 25px auto;
    padding: 10px 25px;
    text-align: center;
  }
  
  .newnav {
    background: #2c6bdc;
    background: linear-gradient(45deg, #2c6bdc, #540e9a 63%);
  }
  
  #intro.intro-cloudalt header h2 {
    color: hsla(0, 0%, 100%, 0.65);
    font-size: 25px;
    margin-bottom: 15px;
  }  
  .ul {
      list-style: none;
      margin: 35px 0;
      padding: 0;
  }
  .li {
      border-bottom: 1px solid hsla(0,0%,100%,.15);
      padding: 10px 0;
  }

  .div_check {
    background: url(./assets/img/back_check.png) no-repeat;
    background-size: cover;
    width: 40px;
    height: 40px;
    padding-block: 5px;
    padding-left: 5px;
}
.div_check2 {
  background: url(./assets/img/back_check.png) no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
  padding-block: 5px;
  padding-left: 5px;
}
  .checkmark {
    float: right;
    display: block;
    stroke-width: 10;
    stroke: white;
    stroke-dashoffset: 0;
  }

.shawdow{
  box-shadow: 0px 1px 10px #999;
  background-color: rgba(255,255,255, 0.8);
}


/*  Hamburger menu css */

*{box-sizing: border-box}

.menu_hamburger1{

	display:none

	}

.menu_hamburger1 + label{
transform: translateY(2px);
position: relative;
width: 35px;
background-color: #011a43;
height: 35px;
cursor: pointer;
display: inline-block

	}

.menu_hamburger1 + label span{

position: absolute;
top: 17px;
left: 6px;
right: 6px;
height: 2px;
background: hsl(0, 0%, 100%)

	}

.menu_hamburger1 + label span:after,
.menu_hamburger1 + label span:before{

position: absolute;
left: 0;
width: 100%;
height: 2px;
background-color: hsl(0, 0%, 100%);
content: ""

	}

.menu_hamburger1 + label span:before{

	top: -8px

	}

.menu_hamburger1 + label span:after{

	bottom: -8px

	}

.menu_hamburger1 + label span:after,
.menu_hamburger1 + label span:before{

	transition: .3s

	}


.menu_hamburger1:checked +label{

	background-color: #011a43

	}

.menu_hamburger1:checked + label span{

transition: 1s;
transform: rotate(45deg)

	}

.menu_hamburger1:checked + label span:before{

	top:0

	}

.menu_hamburger1:checked +label span:after{

bottom:0;
transform:rotate(-90deg)

}

  .paypal-button-row{
    display: none !important
  }

.test{
  background-color: red !important;
}

.fileframe1{
  /* width: 100%; */
  height: 500px;
  border: 9px solid #0000001a;
  border-radius: 5px;
  box-sizing:border-box;
}

.reward .top2 svg, .billPoint svg {
  font-size: 30px;
  font-weight: bold;
  width: 115px;
  height: 70px;
  fill: white;
}

.reward .svg2 {
  width: 355px !important;
}
.reward .svg0, .billPoint .svg0{
  width: 125px !important;
}
.reward .svg1 {
  width: 170px !important;
}
.reward .svg4 {
  width: 160px !important;
   font-size: 20px !important;
}
.reward .svg5 {
  width: 90px !important;
   font-size: 30px;
   height: 50px !important;
}


.svgText {
  fill: white;
  stroke: black;
  stroke-width: 8px;
  stroke-linejoin: round;
  paint-order: stroke;
}
.svgTextDark {
  fill: black !important;
  stroke: #BCCBD3 !important;
  stroke-width: 8px;
  stroke-linejoin: round;
  paint-order: stroke;
}

#diamond {
      width: 0;
      height: 0;
      border: 50px solid transparent;
      border-bottom-color: red;
      position: relative;
      top: -50px;
    }
#diamond:after {
  content: '';
  position: absolute;
  left: -50px;
  top: 50px;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-top-color: red;
}


@keyframes opacity1 {
  from {opacity: 0} 
  to {opacity: 1}
}


.server {
  animation-name: opacity1;
  animation-duration: 1s;
}

.progress-bar {
  position: relative;
  height: 20px !important;
  width: 200px;
  background: white;
  border-radius: 25px;
  border: 3px solid white;
  background-color: white;
  /* padding-left: 10px; */
  box-shadow: 0 0 32px blue;
}

.progress-fill {
  position: absolute;
  height: 14px !important;
  width: 0%;
  animation: progress-forward 3s infinite;
  background: rgb(34, 193, 195);
  background: linear-gradient(
    90deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(45, 63, 253, 1) 100%
  );
  border-radius: 15px;
}

@keyframes progress-forward {
  0% {
    width: 0%;
  }
  25% {
    width: 50%;
  }
  50% {
    width: 75%;
  }
  75% {
    width: 85%;
  }
  100% {
    width: 100%;
  }
} ;
 
