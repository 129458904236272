/* ------------------------------------------------------------------------------------------------------

Name: Traverse through scenes
Description: Makes it incredibly easy to trigger scene changes using the checkbox *hack*
Instead of doing  input#index39:checked + input + div + div { props }

--------------------------------------------------------------------------------------------------------- */
input[type=radio] {
  /*display: none;*/
}

/* ----------------------------------

Checked not checked mixins

------------------------------------- */
/* ----------------------------------

Init the traverse

------------------------------------- */
input#index1:checked + input + input + input + input + input + div + div + div + div + div {
  transform: scale(1);
}

input#index1:not(:checked) + input + input + input + input + input + div + div + div + div + div {
  transform: scale(0);
}

input#index1:checked + input + input + input + input + input + div + div + div {
  background-position: -2200px 0;
}

input#index1:checked + input + input + input + input + input + div + div + div + div {
  background-position: -1000px 0;
}

input#index1:checked + input + input + input + input + input + div {
  right: 300px;
}

/* ----------------------------------

Animation chains

------------------------------------- */
input#index1:checked + input + input + input + input + input + div + div + div + div + div p {
  -webkit-animation: in 1s 1s forwards;
          animation: in 1s 1s forwards;
}

input#index1:not(:checked) + input + input + input + input + input + div + div + div + div + div.start .intro_small {
  -webkit-animation: scaleBack 0.3s 0.6s forwards;
          animation: scaleBack 0.3s 0.6s forwards;
  transform: scale(1);
}
input#index1:not(:checked) + input + input + input + input + input + div + div + div + div + div.start .intro_to {
  -webkit-animation: scaleBack 0.3s 0.7s forwards;
          animation: scaleBack 0.3s 0.7s forwards;
  transform: scale(1);
}
input#index1:not(:checked) + input + input + input + input + input + div + div + div + div + div.start .intro_large span {
  transform: scale(1);
}
input#index1:not(:checked) + input + input + input + input + input + div + div + div + div + div.start .intro_large span:nth-of-type(1) {
  -webkit-animation: scaleBack 0.3s 0.5s forwards;
          animation: scaleBack 0.3s 0.5s forwards;
}
input#index1:not(:checked) + input + input + input + input + input + div + div + div + div + div.start .intro_large span:nth-of-type(2) {
  -webkit-animation: scaleBack 0.3s 0.3s forwards;
          animation: scaleBack 0.3s 0.3s forwards;
}
input#index1:not(:checked) + input + input + input + input + input + div + div + div + div + div.start .intro_large span:nth-of-type(3) {
  -webkit-animation: scaleBack 0.3s 0.4s forwards;
          animation: scaleBack 0.3s 0.4s forwards;
}
input#index1:not(:checked) + input + input + input + input + input + div + div + div + div + div.start .intro_large span:nth-of-type(4) {
  -webkit-animation: scaleBack 0.3s 0.2s forwards;
          animation: scaleBack 0.3s 0.2s forwards;
}
input#index1:not(:checked) + input + input + input + input + input + div + div + div + div + div.start .intro_subheading {
  -webkit-animation: scaleBack 0.3s 0.1s forwards;
          animation: scaleBack 0.3s 0.1s forwards;
  transform: scale(1);
}
input#index1:not(:checked) + input + input + input + input + input + div + div + div + div + div.start label {
  -webkit-animation: scaleBack 0.3s 0s forwards;
          animation: scaleBack 0.3s 0s forwards;
  transform: scale(1);
}

input#index2:checked + input + input + input + input + div + div + div + div + div + div {
  transform: scale(1);
}

input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div {
  transform: scale(0);
}

input#index2:checked + input + input + input + input + div + div + div {
  background-position: -4400px 0;
}

input#index2:checked + input + input + input + input + div + div + div + div {
  background-position: -2000px 0;
}

input#index2:checked + input + input + input + input + div {
  right: 2200px;
}

/* ----------------------------------

Animation chains

------------------------------------- */
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_peeps img {
  -webkit-animation: slideUp 0.8s 2s forwards;
          animation: slideUp 0.8s 2s forwards;
  bottom: -400px;
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development {
  -webkit-animation: bob 3s 1.8s infinite;
          animation: bob 3s 1.8s infinite;
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__small {
  -webkit-animation: scale 1s 2s forwards;
          animation: scale 1s 2s forwards;
  transform: scale(0);
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__mars img {
  -webkit-animation: scale 1s 2.1s forwards;
          animation: scale 1s 2.1s forwards;
  cursor: pointer;
  transform: scale(0);
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(1) {
  -webkit-animation: scale 1s 2.06s forwards;
          animation: scale 1s 2.06s forwards;
  transform: scale(0);
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(2) {
  -webkit-animation: scale 1s 2.12s forwards;
          animation: scale 1s 2.12s forwards;
  transform: scale(0);
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(3) {
  -webkit-animation: scale 1s 2.18s forwards;
          animation: scale 1s 2.18s forwards;
  transform: scale(0);
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(4) {
  -webkit-animation: scale 1s 2.24s forwards;
          animation: scale 1s 2.24s forwards;
  transform: scale(0);
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(5) {
  -webkit-animation: scale 1s 2.3s forwards;
          animation: scale 1s 2.3s forwards;
  transform: scale(0);
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(6) {
  -webkit-animation: scale 1s 2.36s forwards;
          animation: scale 1s 2.36s forwards;
  transform: scale(0);
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(7) {
  -webkit-animation: scale 1s 2.42s forwards;
          animation: scale 1s 2.42s forwards;
  transform: scale(0);
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(8) {
  -webkit-animation: scale 1s 2.48s forwards;
          animation: scale 1s 2.48s forwards;
  transform: scale(0);
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(9) {
  -webkit-animation: scale 1s 2.54s forwards;
          animation: scale 1s 2.54s forwards;
  transform: scale(0);
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(10) {
  -webkit-animation: scale 1s 2.6s forwards;
          animation: scale 1s 2.6s forwards;
  transform: scale(0);
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(11) {
  -webkit-animation: scale 1s 2.66s forwards;
          animation: scale 1s 2.66s forwards;
  transform: scale(0);
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(12) {
  -webkit-animation: scale 1s 2.72s forwards;
          animation: scale 1s 2.72s forwards;
  transform: scale(0);
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(13) {
  -webkit-animation: scale 1s 2.78s forwards;
          animation: scale 1s 2.78s forwards;
  transform: scale(0);
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(14) {
  -webkit-animation: scale 1s 2.84s forwards;
          animation: scale 1s 2.84s forwards;
  transform: scale(0);
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(15) {
  -webkit-animation: scale 1s 2.9s forwards;
          animation: scale 1s 2.9s forwards;
  transform: scale(0);
}
input#index2:checked + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(16) {
  -webkit-animation: scale 1s 2.96s forwards;
          animation: scale 1s 2.96s forwards;
  transform: scale(0);
}

input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_peeps img {
  -webkit-animation: slideBack 0.5s 0s forwards;
          animation: slideBack 0.5s 0s forwards;
  bottom: 0;
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development {
  -webkit-animation: bob 3s 1.8s infinite;
          animation: bob 3s 1.8s infinite;
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__small {
  -webkit-animation: scaleBack 0.3s 0.1s forwards;
          animation: scaleBack 0.3s 0.1s forwards;
  transform: scale(1);
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__mars img {
  -webkit-animation: scaleBack 0.3s 0.2s forwards;
          animation: scaleBack 0.3s 0.2s forwards;
  transform: scale(1);
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(1) {
  -webkit-animation: scaleBack 0.3s 0.34s forwards;
          animation: scaleBack 0.3s 0.34s forwards;
  transform: scale(1);
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(2) {
  -webkit-animation: scaleBack 0.3s 0.38s forwards;
          animation: scaleBack 0.3s 0.38s forwards;
  transform: scale(1);
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(3) {
  -webkit-animation: scaleBack 0.3s 0.42s forwards;
          animation: scaleBack 0.3s 0.42s forwards;
  transform: scale(1);
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(4) {
  -webkit-animation: scaleBack 0.3s 0.46s forwards;
          animation: scaleBack 0.3s 0.46s forwards;
  transform: scale(1);
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(5) {
  -webkit-animation: scaleBack 0.3s 0.5s forwards;
          animation: scaleBack 0.3s 0.5s forwards;
  transform: scale(1);
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(6) {
  -webkit-animation: scaleBack 0.3s 0.54s forwards;
          animation: scaleBack 0.3s 0.54s forwards;
  transform: scale(1);
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(7) {
  -webkit-animation: scaleBack 0.3s 0.58s forwards;
          animation: scaleBack 0.3s 0.58s forwards;
  transform: scale(1);
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(8) {
  -webkit-animation: scaleBack 0.3s 0.62s forwards;
          animation: scaleBack 0.3s 0.62s forwards;
  transform: scale(1);
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(9) {
  -webkit-animation: scaleBack 0.3s 0.66s forwards;
          animation: scaleBack 0.3s 0.66s forwards;
  transform: scale(1);
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(10) {
  -webkit-animation: scaleBack 0.3s 0.7s forwards;
          animation: scaleBack 0.3s 0.7s forwards;
  transform: scale(1);
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(11) {
  -webkit-animation: scaleBack 0.3s 0.74s forwards;
          animation: scaleBack 0.3s 0.74s forwards;
  transform: scale(1);
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(12) {
  -webkit-animation: scaleBack 0.3s 0.78s forwards;
          animation: scaleBack 0.3s 0.78s forwards;
  transform: scale(1);
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(13) {
  -webkit-animation: scaleBack 0.3s 0.82s forwards;
          animation: scaleBack 0.3s 0.82s forwards;
  transform: scale(1);
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(14) {
  -webkit-animation: scaleBack 0.3s 0.86s forwards;
          animation: scaleBack 0.3s 0.86s forwards;
  transform: scale(1);
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(15) {
  -webkit-animation: scaleBack 0.3s 0.9s forwards;
          animation: scaleBack 0.3s 0.9s forwards;
  transform: scale(1);
}
input#index2:not(:checked) + input + input + input + input + div + div + div + div + div + div .intro_development__large span:nth-of-type(16) {
  -webkit-animation: scaleBack 0.3s 0.94s forwards;
          animation: scaleBack 0.3s 0.94s forwards;
  transform: scale(1);
}

input#index3:checked + input + input + input + div + div + div + div + div + div + div {
  transform: scale(1);
}

input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div {
  transform: scale(0);
}

input#index3:checked + input + input + input + div + div + div {
  background-position: -6600px 0;
}

input#index3:checked + input + input + input + div + div + div + div {
  background-position: -3000px 0;
}

input#index3:checked + input + input + input + div {
  right: 4100px;
}

/* ----------------------------------

Animation chains

------------------------------------- */
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people label:nth-of-type(1) {
  -webkit-animation: scale 1s 2s forwards;
          animation: scale 1s 2s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people label:nth-of-type(2) {
  -webkit-animation: scale 1s 2.1s forwards;
          animation: scale 1s 2.1s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people label:nth-of-type(3) {
  -webkit-animation: scale 1s 2.2s forwards;
          animation: scale 1s 2.2s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__small {
  -webkit-animation: scale 1s 2.3s forwards;
          animation: scale 1s 2.3s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__select {
  -webkit-animation: scale 1s 2.4s forwards;
          animation: scale 1s 2.4s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(1) {
  -webkit-animation: scale 1s 2.06s forwards;
          animation: scale 1s 2.06s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(2) {
  -webkit-animation: scale 1s 2.12s forwards;
          animation: scale 1s 2.12s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(3) {
  -webkit-animation: scale 1s 2.18s forwards;
          animation: scale 1s 2.18s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(4) {
  -webkit-animation: scale 1s 2.24s forwards;
          animation: scale 1s 2.24s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(5) {
  -webkit-animation: scale 1s 2.3s forwards;
          animation: scale 1s 2.3s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(6) {
  -webkit-animation: scale 1s 2.36s forwards;
          animation: scale 1s 2.36s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(7) {
  -webkit-animation: scale 1s 2.42s forwards;
          animation: scale 1s 2.42s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(8) {
  -webkit-animation: scale 1s 2.48s forwards;
          animation: scale 1s 2.48s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(9) {
  -webkit-animation: scale 1s 2.54s forwards;
          animation: scale 1s 2.54s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(10) {
  -webkit-animation: scale 1s 2.6s forwards;
          animation: scale 1s 2.6s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(11) {
  -webkit-animation: scale 1s 2.66s forwards;
          animation: scale 1s 2.66s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(12) {
  -webkit-animation: scale 1s 2.72s forwards;
          animation: scale 1s 2.72s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(13) {
  -webkit-animation: scale 1s 2.78s forwards;
          animation: scale 1s 2.78s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(14) {
  -webkit-animation: scale 1s 2.84s forwards;
          animation: scale 1s 2.84s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(15) {
  -webkit-animation: scale 1s 2.9s forwards;
          animation: scale 1s 2.9s forwards;
  transform: scale(0);
}
input#index3:checked + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(16) {
  -webkit-animation: scale 1s 2.96s forwards;
          animation: scale 1s 2.96s forwards;
  transform: scale(0);
}

input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people label:nth-of-type(1) {
  -webkit-animation: scaleBack 0.3s 0s forwards;
          animation: scaleBack 0.3s 0s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people label:nth-of-type(2) {
  -webkit-animation: scaleBack 0.3s 0.1s forwards;
          animation: scaleBack 0.3s 0.1s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people label:nth-of-type(3) {
  -webkit-animation: scaleBack 0.3s 0.2s forwards;
          animation: scaleBack 0.3s 0.2s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__select {
  -webkit-animation: scaleBack 0.3s 0.4s forwards;
          animation: scaleBack 0.3s 0.4s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__small {
  -webkit-animation: scaleBack 0.3s 0.3s forwards;
          animation: scaleBack 0.3s 0.3s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(1) {
  -webkit-animation: scaleBack 0.3s 0.06s forwards;
          animation: scaleBack 0.3s 0.06s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(2) {
  -webkit-animation: scaleBack 0.3s 0.12s forwards;
          animation: scaleBack 0.3s 0.12s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(3) {
  -webkit-animation: scaleBack 0.3s 0.18s forwards;
          animation: scaleBack 0.3s 0.18s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(4) {
  -webkit-animation: scaleBack 0.3s 0.24s forwards;
          animation: scaleBack 0.3s 0.24s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(5) {
  -webkit-animation: scaleBack 0.3s 0.3s forwards;
          animation: scaleBack 0.3s 0.3s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(6) {
  -webkit-animation: scaleBack 0.3s 0.36s forwards;
          animation: scaleBack 0.3s 0.36s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(7) {
  -webkit-animation: scaleBack 0.3s 0.42s forwards;
          animation: scaleBack 0.3s 0.42s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(8) {
  -webkit-animation: scaleBack 0.3s 0.48s forwards;
          animation: scaleBack 0.3s 0.48s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(9) {
  -webkit-animation: scaleBack 0.3s 0.54s forwards;
          animation: scaleBack 0.3s 0.54s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(10) {
  -webkit-animation: scaleBack 0.3s 0.6s forwards;
          animation: scaleBack 0.3s 0.6s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(11) {
  -webkit-animation: scaleBack 0.3s 0.66s forwards;
          animation: scaleBack 0.3s 0.66s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(12) {
  -webkit-animation: scaleBack 0.3s 0.72s forwards;
          animation: scaleBack 0.3s 0.72s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(13) {
  -webkit-animation: scaleBack 0.3s 0.78s forwards;
          animation: scaleBack 0.3s 0.78s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(14) {
  -webkit-animation: scaleBack 0.3s 0.84s forwards;
          animation: scaleBack 0.3s 0.84s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(15) {
  -webkit-animation: scaleBack 0.3s 0.9s forwards;
          animation: scaleBack 0.3s 0.9s forwards;
  transform: scale(1);
}
input#index3:not(:checked) + input + input + input + div + div + div + div + div + div + div.people .intro_people__large span:nth-of-type(16) {
  -webkit-animation: scaleBack 0.3s 0.96s forwards;
          animation: scaleBack 0.3s 0.96s forwards;
  transform: scale(1);
}

input#index4:checked + input + input + div + div + div + div + div + div + div + div {
  transform: scale(1);
}

input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div {
  transform: scale(0);
}

input#index4:checked + input + input + div + div + div {
  background-position: -8800px 0;
}

input#index4:checked + input + input + div + div + div + div {
  background-position: -4000px 0;
}

input#index4:checked + input + input + div {
  right: 6000px;
}

/* ----------------------------------

Animation chains

------------------------------------- */
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab label:nth-of-type(1) {
  -webkit-animation: scale 1s 2s forwards;
          animation: scale 1s 2s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab label:nth-of-type(2) {
  -webkit-animation: scale 1s 2.1s forwards;
          animation: scale 1s 2.1s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab label:nth-of-type(3) {
  -webkit-animation: scale 1s 2.2s forwards;
          animation: scale 1s 2.2s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__small {
  -webkit-animation: scale 1s 2.3s forwards;
          animation: scale 1s 2.3s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__select {
  -webkit-animation: scale 1s 2.4s forwards;
          animation: scale 1s 2.4s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(1) {
  -webkit-animation: scale 1s 2.06s forwards;
          animation: scale 1s 2.06s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(2) {
  -webkit-animation: scale 1s 2.12s forwards;
          animation: scale 1s 2.12s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(3) {
  -webkit-animation: scale 1s 2.18s forwards;
          animation: scale 1s 2.18s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(4) {
  -webkit-animation: scale 1s 2.24s forwards;
          animation: scale 1s 2.24s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(5) {
  -webkit-animation: scale 1s 2.3s forwards;
          animation: scale 1s 2.3s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(6) {
  -webkit-animation: scale 1s 2.36s forwards;
          animation: scale 1s 2.36s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(7) {
  -webkit-animation: scale 1s 2.42s forwards;
          animation: scale 1s 2.42s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(8) {
  -webkit-animation: scale 1s 2.48s forwards;
          animation: scale 1s 2.48s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(9) {
  -webkit-animation: scale 1s 2.54s forwards;
          animation: scale 1s 2.54s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(10) {
  -webkit-animation: scale 1s 2.6s forwards;
          animation: scale 1s 2.6s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(11) {
  -webkit-animation: scale 1s 2.66s forwards;
          animation: scale 1s 2.66s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(12) {
  -webkit-animation: scale 1s 2.72s forwards;
          animation: scale 1s 2.72s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(13) {
  -webkit-animation: scale 1s 2.78s forwards;
          animation: scale 1s 2.78s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(14) {
  -webkit-animation: scale 1s 2.84s forwards;
          animation: scale 1s 2.84s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(15) {
  -webkit-animation: scale 1s 2.9s forwards;
          animation: scale 1s 2.9s forwards;
  transform: scale(0);
}
input#index4:checked + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(16) {
  -webkit-animation: scale 1s 2.96s forwards;
          animation: scale 1s 2.96s forwards;
  transform: scale(0);
}

input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab label:nth-of-type(1) {
  -webkit-animation: scaleBack 0.3s 0s forwards;
          animation: scaleBack 0.3s 0s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab label:nth-of-type(2) {
  -webkit-animation: scaleBack 0.3s 0.1s forwards;
          animation: scaleBack 0.3s 0.1s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab label:nth-of-type(3) {
  -webkit-animation: scaleBack 0.3s 0.2s forwards;
          animation: scaleBack 0.3s 0.2s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__select {
  -webkit-animation: scaleBack 0.3s 0.4s forwards;
          animation: scaleBack 0.3s 0.4s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__small {
  -webkit-animation: scaleBack 0.3s 0.3s forwards;
          animation: scaleBack 0.3s 0.3s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(1) {
  -webkit-animation: scaleBack 0.3s 0.06s forwards;
          animation: scaleBack 0.3s 0.06s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(2) {
  -webkit-animation: scaleBack 0.3s 0.12s forwards;
          animation: scaleBack 0.3s 0.12s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(3) {
  -webkit-animation: scaleBack 0.3s 0.18s forwards;
          animation: scaleBack 0.3s 0.18s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(4) {
  -webkit-animation: scaleBack 0.3s 0.24s forwards;
          animation: scaleBack 0.3s 0.24s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(5) {
  -webkit-animation: scaleBack 0.3s 0.3s forwards;
          animation: scaleBack 0.3s 0.3s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(6) {
  -webkit-animation: scaleBack 0.3s 0.36s forwards;
          animation: scaleBack 0.3s 0.36s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(7) {
  -webkit-animation: scaleBack 0.3s 0.42s forwards;
          animation: scaleBack 0.3s 0.42s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(8) {
  -webkit-animation: scaleBack 0.3s 0.48s forwards;
          animation: scaleBack 0.3s 0.48s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(9) {
  -webkit-animation: scaleBack 0.3s 0.54s forwards;
          animation: scaleBack 0.3s 0.54s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(10) {
  -webkit-animation: scaleBack 0.3s 0.6s forwards;
          animation: scaleBack 0.3s 0.6s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(11) {
  -webkit-animation: scaleBack 0.3s 0.66s forwards;
          animation: scaleBack 0.3s 0.66s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(12) {
  -webkit-animation: scaleBack 0.3s 0.72s forwards;
          animation: scaleBack 0.3s 0.72s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(13) {
  -webkit-animation: scaleBack 0.3s 0.78s forwards;
          animation: scaleBack 0.3s 0.78s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(14) {
  -webkit-animation: scaleBack 0.3s 0.84s forwards;
          animation: scaleBack 0.3s 0.84s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(15) {
  -webkit-animation: scaleBack 0.3s 0.9s forwards;
          animation: scaleBack 0.3s 0.9s forwards;
  transform: scale(1);
}
input#index4:not(:checked) + input + input + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(16) {
  -webkit-animation: scaleBack 0.3s 0.96s forwards;
          animation: scaleBack 0.3s 0.96s forwards;
  transform: scale(1);
}

input#index5:checked + input + div + div + div + div + div + div + div + div + div {
  transform: scale(1);
}

input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div {
  transform: scale(0);
}

input#index5:checked + input + div + div + div {
  background-position: -11000px 0;
}

input#index5:checked + input + div + div + div + div {
  background-position: -5000px 0;
}

input#index5:checked + input + div {
  right: 7900px;
}

/* ----------------------------------

Animation chains

------------------------------------- */
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab label:nth-of-type(1) {
  -webkit-animation: scale 1s 2s forwards;
          animation: scale 1s 2s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab label:nth-of-type(2) {
  -webkit-animation: scale 1s 2.1s forwards;
          animation: scale 1s 2.1s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab label:nth-of-type(3) {
  -webkit-animation: scale 1s 2.2s forwards;
          animation: scale 1s 2.2s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__small {
  -webkit-animation: scale 1s 2.3s forwards;
          animation: scale 1s 2.3s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__select {
  -webkit-animation: scale 1s 2.4s forwards;
          animation: scale 1s 2.4s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(1) {
  -webkit-animation: scale 1s 2.06s forwards;
          animation: scale 1s 2.06s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(2) {
  -webkit-animation: scale 1s 2.12s forwards;
          animation: scale 1s 2.12s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(3) {
  -webkit-animation: scale 1s 2.18s forwards;
          animation: scale 1s 2.18s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(4) {
  -webkit-animation: scale 1s 2.24s forwards;
          animation: scale 1s 2.24s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(5) {
  -webkit-animation: scale 1s 2.3s forwards;
          animation: scale 1s 2.3s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(6) {
  -webkit-animation: scale 1s 2.36s forwards;
          animation: scale 1s 2.36s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(7) {
  -webkit-animation: scale 1s 2.42s forwards;
          animation: scale 1s 2.42s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(8) {
  -webkit-animation: scale 1s 2.48s forwards;
          animation: scale 1s 2.48s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(9) {
  -webkit-animation: scale 1s 2.54s forwards;
          animation: scale 1s 2.54s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(10) {
  -webkit-animation: scale 1s 2.6s forwards;
          animation: scale 1s 2.6s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(11) {
  -webkit-animation: scale 1s 2.66s forwards;
          animation: scale 1s 2.66s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(12) {
  -webkit-animation: scale 1s 2.72s forwards;
          animation: scale 1s 2.72s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(13) {
  -webkit-animation: scale 1s 2.78s forwards;
          animation: scale 1s 2.78s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(14) {
  -webkit-animation: scale 1s 2.84s forwards;
          animation: scale 1s 2.84s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(15) {
  -webkit-animation: scale 1s 2.9s forwards;
          animation: scale 1s 2.9s forwards;
  transform: scale(0);
}
input#index5:checked + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(16) {
  -webkit-animation: scale 1s 2.96s forwards;
          animation: scale 1s 2.96s forwards;
  transform: scale(0);
}

input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab label:nth-of-type(1) {
  -webkit-animation: scaleBack 0.3s 0s forwards;
          animation: scaleBack 0.3s 0s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab label:nth-of-type(2) {
  -webkit-animation: scaleBack 0.3s 0.1s forwards;
          animation: scaleBack 0.3s 0.1s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab label:nth-of-type(3) {
  -webkit-animation: scaleBack 0.3s 0.2s forwards;
          animation: scaleBack 0.3s 0.2s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__select {
  -webkit-animation: scaleBack 0.3s 0.4s forwards;
          animation: scaleBack 0.3s 0.4s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__small {
  -webkit-animation: scaleBack 0.3s 0.3s forwards;
          animation: scaleBack 0.3s 0.3s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(1) {
  -webkit-animation: scaleBack 0.3s 0.06s forwards;
          animation: scaleBack 0.3s 0.06s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(2) {
  -webkit-animation: scaleBack 0.3s 0.12s forwards;
          animation: scaleBack 0.3s 0.12s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(3) {
  -webkit-animation: scaleBack 0.3s 0.18s forwards;
          animation: scaleBack 0.3s 0.18s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(4) {
  -webkit-animation: scaleBack 0.3s 0.24s forwards;
          animation: scaleBack 0.3s 0.24s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(5) {
  -webkit-animation: scaleBack 0.3s 0.3s forwards;
          animation: scaleBack 0.3s 0.3s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(6) {
  -webkit-animation: scaleBack 0.3s 0.36s forwards;
          animation: scaleBack 0.3s 0.36s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(7) {
  -webkit-animation: scaleBack 0.3s 0.42s forwards;
          animation: scaleBack 0.3s 0.42s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(8) {
  -webkit-animation: scaleBack 0.3s 0.48s forwards;
          animation: scaleBack 0.3s 0.48s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(9) {
  -webkit-animation: scaleBack 0.3s 0.54s forwards;
          animation: scaleBack 0.3s 0.54s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(10) {
  -webkit-animation: scaleBack 0.3s 0.6s forwards;
          animation: scaleBack 0.3s 0.6s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(11) {
  -webkit-animation: scaleBack 0.3s 0.66s forwards;
          animation: scaleBack 0.3s 0.66s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(12) {
  -webkit-animation: scaleBack 0.3s 0.72s forwards;
          animation: scaleBack 0.3s 0.72s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(13) {
  -webkit-animation: scaleBack 0.3s 0.78s forwards;
          animation: scaleBack 0.3s 0.78s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(14) {
  -webkit-animation: scaleBack 0.3s 0.84s forwards;
          animation: scaleBack 0.3s 0.84s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(15) {
  -webkit-animation: scaleBack 0.3s 0.9s forwards;
          animation: scaleBack 0.3s 0.9s forwards;
  transform: scale(1);
}
input#index5:not(:checked) + input + div + div + div + div + div + div + div + div + div.hab .intro_hab__large span:nth-of-type(16) {
  -webkit-animation: scaleBack 0.3s 0.96s forwards;
          animation: scaleBack 0.3s 0.96s forwards;
  transform: scale(1);
}

input#index6:checked + div + div + div + div + div + div + div + div + div + div {
  transform: scale(1);
}

input#index6:not(:checked) + div + div + div + div + div + div + div + div + div + div {
  transform: scale(0);
}

input#index6:checked + div + div + div {
  background-position: -13200px 0;
}

input#index6:checked + div + div + div + div {
  background-position: -6000px 0;
}

input#index6:checked + div {
  right: 9800px;
}

/* ----------------------------------

Animation chains

------------------------------------- */
/* ----------------------------------

Styling

------------------------------------- */




.mars_truck {
  position: absolute;
  width: 100px;
  right: 130px;
  transition: all 2s 0.5s cubic-bezier(0.75, 0.01, 0, 1);
  z-index: 0;
  bottom: 80px;
  -webkit-animation: truck 300s linear infinite;
          animation: truck 300s linear infinite;
}
.mars_truck__body {
  -webkit-animation: scale 1s 1.9s forwards;
          animation: scale 1s 1.9s forwards;
  transform: scale(0);
}
.mars_truck__body img {
  width: 100%;
}
.mars_truck__wheel, .mars_truck__wheel--two {
  -webkit-animation: scale 1s 1.9s forwards;
          animation: scale 1s 1.9s forwards;
  transform: scale(0);
}
.mars_truck__wheel img, .mars_truck__wheel--two img {
  position: absolute;
  transform-origin: 50% 50%;
  width: 21px;
  bottom: -5px;
  right: 71px;
  -webkit-animation: spinReverse 2s linear infinite;
          animation: spinReverse 2s linear infinite;
}
.mars_truck__wheel--two img {
  right: 19px;
}


.mars_parallax, .mars_parallax--two {
  background: url("mars_p_1-2.svg");
  width: calc(100% - 12px);
  position: fixed;
  transition: all 2s 0.5s cubic-bezier(0.75, 0.01, 0, 1);
  bottom: 0px;
  background-position: 0 0;
  height: 300px;
  background-repeat: repeat-x;
  background-size: auto 100% !important;
  -webkit-animation: scale 1s 1.9s forwards;
          animation: scale 1s 1.9s forwards;
  transform: scale(0);
}
.mars_parallax{
  z-index: -1;
}
.mars_parallax--two {
  background: url("mars_p_2-2.svg");
  z-index: -2;
  height: 250px;
  transform: scale(0);
  -webkit-animation: scale 1s 2s forwards;
          animation: scale 1s 2s forwards;
}



@-webkit-keyframes in {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes in {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes out {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
@keyframes out {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes logoIn {
  from {
    top: 50%;
    transform: scale(1) translateY(-50%);
  }
  to {
    top: calc(0% + 40px);
    transform: scale(1) translateY(-50%);
  }
}
@keyframes logoIn {
  from {
    top: 50%;
    transform: scale(1) translateY(-50%);
  }
  to {
    top: calc(0% + 40px);
    transform: scale(1) translateY(-50%);
  }
}
@-webkit-keyframes slideUp {
  from {
    bottom: -400px;
  }
  to {
    bottom: 0;
  }
}
@keyframes slideUp {
  from {
    bottom: -400px;
  }
  to {
    bottom: 0;
  }
}
@-webkit-keyframes slideBack {
  from {
    bottom: 0;
  }
  to {
    bottom: -400px;
  }
}
@keyframes slideBack {
  from {
    bottom: 0;
  }
  to {
    bottom: -400px;
  }
}
/*@-webkit-keyframes spin {
  to {
    transform: translateY(-50%) rotate(360deg);
  }
}
@keyframes spin {
  to {
    transform: translateY(-50%) rotate(360deg);
  }
}*/
@-webkit-keyframes truck {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-500px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes truck {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-500px);
  }
  100% {
    transform: translateX(0px);
  }
}
@-webkit-keyframes filled {
  from {
    width: 0%;
  }
  to {
    width: 97%;
  }
}
@keyframes filled {
  from {
    width: 0%;
  }
  to {
    width: 97%;
  }
}
@-webkit-keyframes spinReverse {
  from {
    transform: translateY(32px) rotate(0deg);
    bottom: -16px;
  }
  to {
    transform: translateY(32px) rotate(-360deg);
    bottom: -16px;
  }
}
@keyframes spinReverse {
  from {
    transform: translateY(32px) rotate(0deg);
    bottom: -16px;
  }
  to {
    transform: translateY(32px) rotate(-360deg);
    bottom: -16px;
  }
}
@-webkit-keyframes scale {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(0.98);
  }
  60% {
    transform: scale(1.012);
  }
  80% {
    transform: scale(0.995);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scale {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(0.98);
  }
  60% {
    transform: scale(1.012);
  }
  80% {
    transform: scale(0.995);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes scaleLogo {
  0% {
    transform: scale(0) translateY(-50%);
  }
  20% {
    transform: scale(1.1) translateY(-50%);
  }
  40% {
    transform: scale(0.98) translateY(-50%);
  }
  60% {
    transform: scale(1.012) translateY(-50%);
  }
  80% {
    transform: scale(0.995) translateY(-50%);
  }
  100% {
    transform: scale(1) translateY(-50%);
  }
}
@keyframes scaleLogo {
  0% {
    transform: scale(0) translateY(-50%);
  }
  20% {
    transform: scale(1.1) translateY(-50%);
  }
  40% {
    transform: scale(0.98) translateY(-50%);
  }
  60% {
    transform: scale(1.012) translateY(-50%);
  }
  80% {
    transform: scale(0.995) translateY(-50%);
  }
  100% {
    transform: scale(1) translateY(-50%);
  }
}
@-webkit-keyframes scaleBack {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes scaleBack {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@-webkit-keyframes bob {
  0% {
    transform: translatey(-50%) skewY(-10deg);
  }
  50% {
    transform: translatey(-52%) skewY(-10deg);
  }
  100% {
    transform: translatey(-50%) skewY(-10deg);
  }
}
@keyframes bob {
  0% {
    transform: translatey(-50%) skewY(-10deg);
  }
  50% {
    transform: translatey(-52%) skewY(-10deg);
  }
  100% {
    transform: translatey(-50%) skewY(-10deg);
  }
}
@-webkit-keyframes grain {
  0%, 100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, 5%);
  }
  30% {
    transform: translate(7%, -25%);
  }
  40% {
    transform: translate(-5%, 25%);
  }
  50% {
    transform: translate(-15%, 10%);
  }
  60% {
    transform: translate(15%, 0%);
  }
  70% {
    transform: translate(0%, 15%);
  }
  80% {
    transform: translate(3%, 35%);
  }
  90% {
    transform: translate(-10%, 10%);
  }
}
@keyframes grain {
  0%, 100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, 5%);
  }
  30% {
    transform: translate(7%, -25%);
  }
  40% {
    transform: translate(-5%, 25%);
  }
  50% {
    transform: translate(-15%, 10%);
  }
  60% {
    transform: translate(15%, 0%);
  }
  70% {
    transform: translate(0%, 15%);
  }
  80% {
    transform: translate(3%, 35%);
  }
  90% {
    transform: translate(-10%, 10%);
  }
}
